import React from "react"
import { useState } from "react"
import { Spring, config } from "react-spring/renderprops"
import VisibilitySensor from "react-visibility-sensor"
import * as styles from "./comment-form.module.css"
import Arrow from "./arrow"
import {Container, Row, Col} from "react-bootstrap"

const CommentForm = ({ worker, commentsEndpoint }) => {
  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [commentStatus, setCommentStatus] = useState(false)

  const createComment = () => {
    // Trying to avoid double clicks here.
    if (commentStatus === "loading") {
      return // don't send this twice.
    }
    // This is a POST request to the comments endpoint. The body is sent as a JSON string.
    fetch(commentsEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        author_email: email,
        author_name: userName,
        post: worker,
        content: message,
      }),
    }).then(response => {
      if (response.status === 201) {
        setCommentStatus("success")
      } else {
        setCommentStatus("error")
      }
    })
  }

  // Renders the comment form elements.
  const renderCommentForm = (
    <Container>
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <Spring
            delay={700}
            to={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateX(0)" : "translateY(-50px)",
            }}
            config={config.molasses}
          >
            {props => (
              <div
                style={{
                  ...props,
                }}
              >
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setCommentStatus("loading")
                  }}
                >
                  <Container>
                  <input type="hidden" name="botField" />
                  <Row className={styles.flexDoubles}>
                    <Col xs="6" className={styles.field}>
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        placeholder="Your name"
                      />
                    </Col>
                    <Col xs="6" className={styles.field}>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Your Email"
                      />
                    </Col>
                  </Row>
                  <div className={styles.flexFull}>
                    <div className={styles.field}>
                      <textarea
                        name="message"
                        id="message"
                        rows="6"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        placeholder="Leave a message"
                      />
                    </div>
                    <div className={`${styles.field} ${styles.submitButton}`}>
                      <input
                        type="submit"
                        onClick={createComment}
                        value="submit"
                        className="special"
                      />
                      <span style={{padding: "6px 25px 0"}}><Arrow direction="right" onClick={createComment} width="20px"/></span>
                    </div>
                  </div>
                  </Container>
                </form>
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensor>
    </Container>
  )

  switch (commentStatus) {
    case "success": // A successful submission.
      return (
        <div style={{ textAlign: "center" }}>
          Your message has been successfully submitted.
        </div>
      )
    case "loading": // Just submitted, no response yet.
      return (
        <div style={{ textAlign: "center" }}>
          Please wait. Your message is being submitted.
        </div>
      )
    case "error": // Something went wrong.
      return (
        <div style={{ textAlign: "center" }}>
          There was an error in your submission. Please try again later.
        </div>
      )
    default:
      // No submission, render the form.
      return renderCommentForm
  }
}

export default CommentForm

import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import { Spring, animated, config } from "react-spring/renderprops"
import VisibilitySensor from "react-visibility-sensor"
import * as styles from "./worker.module.css"
// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CommentForm from "../components/commentForm"
import { Container, Row, Col } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"

const WorkerTemplate = ({
  data: { previous, next, worker, wp, allWpWorker },
}) => {
  const featuredImage = {
    fluid: worker.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: worker.featuredImage?.node?.alt || ``,
  }
  const [destination, setDestination] = useState(-1)
  const isDestination = d => {
    return d === destination
  }
  let workerData = worker && worker.workerData
  let workers = allWpWorker.nodes
  const commentsEndpoint = `${wp.generalSettings.url}/wp-json/wp/v2/comments`
  //Remove caching with fetch request
  const timeStamp = +new Date()
  const postCommentsEndpoint = `${commentsEndpoint}?post=${worker.databaseId}&t=${timeStamp}`
  // Setting up state to handle the incoming comments.
  const [comments, setComments] = useState(false)
  useEffect(() => {
    async function getPostComments() {
      const getComments = await fetch(postCommentsEndpoint)
        .then(response => {
          if (response.status !== 200) {
            throw Error(response.message)
          }
          return response
        })
        .then(response => {
          return response.json()
        })
        .catch(error => {
          return []
        })
      return getComments
    }
    let isSubscribed = true
    getPostComments().then(postComments => {
      if (isSubscribed) {
        setComments(postComments)
        window.fullpage_api.reBuild()
      }
    })
    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const isMobile = useMediaQuery({
    query: "(max-width: 42rem)",
  })

  return (
    <Layout handlerDestination={setDestination}>
      <SEO
        title={`${worker.title} - Healthcare Worker | Forever Essential`}
        description={`${worker.title}’s memory is being remembered by Forever Essential, a Healthcare Worker Memorial. Join us in honoring their legacy.`}
        metaImage={featuredImage.fluid}
        fbHeadline={`${worker.title} - Healthcare Worker | Forever Essential`}
        fbDescription={worker.blurb}
        twShare={`${worker.title}’s memory is being remembered by Forever Essential, a Healthcare Worker Memorial. Join us in honoring their legacy.`}
      />
      <div className={`section worker_section`}>
        <Spring
          delay={isDestination(0) ? 100 : 0}
          to={{
            opacity: isDestination(0) ? 1 : 0,
            transform: isDestination(0) ? "translateY(0)" : "translateY(50px)",
          }}
          config={config.molasses}
        >
          {props => (
            <div style={props}>
              <Container style={{ paddingTop: isMobile ? "30px" : "" }}>
                <Row>
                  <Col xs={12} md={6}>
                    {/* if we have a featured image for this post let's display it */}
                    {featuredImage?.fluid && (
                      <Image
                        fluid={featuredImage.fluid}
                        alt={featuredImage.alt}
                        style={{ maxWidth: 500, width: "100%" }}
                      />
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    <div>
                      <h1 itemProp="headline">{parse(worker.title)}</h1>
                      {workerData.blurb ? (
                        <p style={{ fontSize: isMobile ? "1.5rem" : "2.5rem" }}>
                          {workerData.blurb}
                        </p>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </Spring>
      </div>
      <div className={`section worker_section`}>
        <Spring
          delay={isDestination(1) ? 1500 : 0}
          to={{
            opacity: isDestination(1) ? 1 : 0,
            transform: isDestination(1) ? "translateY(0)" : "translateY(200px)",
          }}
          config={config.molasses}
        >
          {props => (
            <div style={props}>
              {workerData.quote ? (
                <Container>
                  <h1 style={{ textAlign: "center" }}>{workerData.quote}</h1>
                </Container>
              ) : null}
            </div>
          )}
        </Spring>
      </div>
      <div className={`section worker_section fp-auto-height`}>
        <Spring
          delay={isDestination(2) ? 100 : 0}
          to={{
            opacity: isDestination(2) ? 1 : 0,
            transform: isDestination(2)
              ? "translateY(0)"
              : "translateY(-200px)",
          }}
          config={config.molasses}
        >
          {props => (
            <div
              style={{
                ...props,
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                padding: "8% 0",
              }}
            >
              <Container style={{ paddingTop: isMobile ? "30px" : "" }}>
                <Row>
                  <Col xs={12} md={6}>
                    {workerData.bio ? (
                      <p style={{ fontSize: isMobile ? "1.5rem" : "2.0rem" }}>
                        {workerData.bio}
                      </p>
                    ) : null}
                  </Col>
                  <Col xs={12} md={6}>
                    {/* if we have a featured image for this post let's display it */}
                    {featuredImage?.fluid && (
                      <Image
                        fluid={featuredImage.fluid}
                        alt={featuredImage.alt}
                        style={{ margin: "0 25px 50px", maxWidth: 500 }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Container>
                    <CommentForm
                      worker={worker.databaseId}
                      commentsEndpoint={commentsEndpoint}
                    />
                  </Container>
                </Row>
              </Container>
            </div>
          )}
        </Spring>
      </div>
      {comments
        ? comments.map((comment, i) => {
            let commentAuthor = comment && comment.author_name
            let commentContent = comment.content && comment.content.rendered
            return (
              <div className="section worker_section" key={i}>
                <VisibilitySensor partialVisibility>
                  {({ isVisible }) => (
                    <Spring
                      delay={0}
                      to={{
                        opacity: isVisible ? 1 : 0,
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <div style={props}>
                          <Container style={{ textAlign: "center" }}>
                            <h1
                              style={{ textAlign: "center", padding: "30px" }}
                            >
                              “{commentContent.replace(/(<([^>]+)>)/gi, "")}”
                            </h1>
                            {commentAuthor ? <p>—{commentAuthor}</p> : null}
                          </Container>
                        </div>
                      )}
                    </Spring>
                  )}
                </VisibilitySensor>
              </div>
            )
          })
        : null}
      {workerData.quote2 ? (
        <div className={`section worker_section`}>
          <VisibilitySensor>
            {({ isVisible }) => (
              <Spring
                delay={300}
                to={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateY(0)" : "translateY(-100px)",
                }}
              >
                {props => (
                  <div style={props}>
                    {workerData.quote2 ? (
                      <Container>
                        <h1 style={{ textAlign: "center" }}>
                          {workerData.quote2}
                        </h1>
                      </Container>
                    ) : null}
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>
      ) : null}
      <div className="section worker_section">
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={0}
              to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(-250px)",
              }}
              config={config.molasses}
            >
              {props => (
                <animated.div style={{ ...props, padding: "30px 0" }}>
                  <Container>
                    {workers.map(singleWorker => {
                      const title = singleWorker.title
                      const currentWorker = title === worker.title
                      if (singleWorker.workerData.nonworker !== true) {
                        return (
                          <span key={singleWorker.uri}>
                            {!currentWorker ? (
                              <span className={styles.memorialWallText}>
                                <Link to={singleWorker.uri} itemProp="url">
                                  <span itemProp="headline">
                                    {`${parse(title)}`}
                                  </span>{" "}
                                  |
                                </Link>
                              </span>
                            ) : (
                              <span
                                key={singleWorker.uri}
                                className={`${styles.currentWorkerText}`}
                              >
                                <span itemProp="headline">
                                  {`${parse(title)}`}
                                </span>
                                <span className={styles.memorialWallText}>
                                  |
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      }
                    })}
                  </Container>
                </animated.div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
    </Layout>
  )
}

export default WorkerTemplate

export const pageQuery = graphql`
  query WorkerById($id: String!) {
    worker: wpWorker(id: { eq: $id }) {
      id
      databaseId
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      workerData {
        job
        city
        blurb
        bio
        quote
        quote2
      }
      comments {
        nodes {
          content
        }
      }
    }
    wp {
      generalSettings {
        url
      }
    }
    allWpWorker(sort: { fields: [title], order: ASC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        workerData {
          nonworker
        }
      }
    }
  }
`
